<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Report attività</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          
        </ion-toolbar>
      </ion-header>

      <div id="container">
        <ion-grid class="ricerca">
          <ion-row>
            <ion-col>
              <ion-label id="ldatafine">
                Riferimento Offerta:
              </ion-label>
              <ion-input id="ricdatafine" v-model="search.ref_off"></ion-input>
            </ion-col>
            <ion-col>
              <ion-label id="ldatainizio">
                Data Inizio:
              </ion-label>
              <ion-datetime id="ricdatainizio" v-model="search.begindate"></ion-datetime>
            </ion-col>
            <ion-col>
              <ion-label id="ldatafine">
                Data Fine:
              </ion-label>
              <ion-datetime id="ricdatafine" v-model="search.enddate"></ion-datetime>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label id="lconsulente">
                Risorsa:
              </ion-label>    
              <ion-select id="riccliente"  v-model="search.consultant">
                <ion-select-option :value="c.id" v-for="c,i in users" :key="'consultant-'+i">{{c.name}}</ion-select-option>
              </ion-select>       
            </ion-col>
          
            <ion-col>
              <ion-label id="lcliente">
                Cliente:
              </ion-label>
              <ion-select id="riccliente"  v-model="search.account">
                <ion-select-option :value="a.id" v-for="a,i in accounts" :key="'account-'+i">{{a.name}}</ion-select-option>
              </ion-select>
            </ion-col>
            <ion-col>
              <ion-label id="ldescrizione">
                Descrizione:
              </ion-label>
              <ion-input id="ricdescrizione" v-model="search.description"></ion-input>
            </ion-col>
            </ion-row>
          <ion-row>
            <ion-col>           
              <ion-label id="lcliente">
                Stato attività:
              </ion-label>
              <ion-select id="riccliente"  v-model="search.status">
                <ion-select-option id="selezione" value='2'>SOLO TERMINATE</ion-select-option>
                <ion-select-option id="selezione" value='1'>SOLO ATTIVE</ion-select-option>
              </ion-select>
            </ion-col>
            <ion-col>

            </ion-col>
            <ion-col>

            </ion-col>
          </ion-row>
          <ion-row v-if="user.role<5">
              <ion-button class="btncerca" @click="getTask()">
                RICERCA
              </ion-button>
              <ion-button class="btnfiltro" color='warning' @click="svuotaFiltro()">
                X
              </ion-button>
          </ion-row>
          <ion-row v-if="user.role>4">
            <ion-button class="btncerca" @click="getAllTask()">
              RICERCA
            </ion-button>
            <ion-button class="btnfiltro" color='warning' @click="svuotaFiltro()">
              X
            </ion-button>
          </ion-row>
        </ion-grid>
        <ion-button id="export" @click="esportaExcel()"  style="width:5%;margin:1%;margin-left:0%;--ion-color-primary:#2c912f" v-if="$store.state.userRole>=5">
          <ion-icon :icon="downloadOutline"></ion-icon>
        </ion-button>
        <dtable
          v-if="user.role<5"
          color="#fff"
          bgcolor="rgb(56,128,255)"
          :heading="tasksHeading"
          :data="tasks"
          :total="table.total"
          :perPage="table.perPage"
          :currentPage="table.currentPage"
          :orderBy="table.orderBy"
          :order="table.order"
          @changePage="getTask"
        > 
          <template v-slot:enddate="slotProps">
            <div v-if="slotProps.row.status!=2" style="background-color:#fdff7a; position:absolute; top:0; left:0; width:100%; height:100%">
              <span style="position:absolute; top:50%; left:0; padding:0 7px; transform:translateY(-50%)"><b>ATTIVA</b></span>
            </div>               
          </template>
          <template v-slot:account="slotProps">
            {{slotProps.row.account_name}}
          </template>
          <template v-slot:btns="slotProps">
            <ion-button @click="$router.push('/folder/dettaglio-attivita/'+slotProps.row.id);">
              <ion-icon name="search-outline"></ion-icon>
            </ion-button>
          </template>
        </dtable>
        <dtable
          v-if="user.role>4"
          color="#fff"
          bgcolor="rgb(56,128,255)"
          :heading="tasksHeadingAdmin"
          :data="tasks"
          :total="table.total"
          :perPage="table.perPage"
          :currentPage="table.currentPage"
          :orderBy="table.orderBy"
          :order="table.order"
          @changePage="getAllTask"
        > 
          <template v-slot:enddate="slotProps">
            <div v-if="slotProps.row.status!=2" style="background-color:#fdff7a; position:absolute; top:0; left:0; width:100%; height:100%">
              <span style="position:absolute; top:50%; left:0; padding:0 7px; transform:translateY(-50%)">ATTIVA</span>
            </div>               
          </template>
          <template v-slot:consultant="slotProps">
            {{slotProps.row.consultant_name}}
          </template>
          <template v-slot:account="slotProps">
            {{slotProps.row.account_name}}
          </template>
          <template v-slot:btns="slotProps">
            <div v-if="slotProps.row.status!=1">
              <ion-button @click="$router.push('/folder/dettaglio-attivita/'+slotProps.row.id);">
                <ion-icon name="search-outline"></ion-icon>
              </ion-button>
            </div>
          </template>
        </dtable>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import { 
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonDatetime,
    IonGrid,
    IonHeader,
    IonIcon,
    IonLabel,
    IonMenuButton,
    IonInput,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar
  } from '@ionic/vue';
  import Dtable from './partials/Dtable'
  import {downloadOutline} from 'ionicons/icons';


  export default {
    name: 'Folder',
    components: {
        IonButton,
        IonButtons,
        IonCol,
        IonContent,
        IonDatetime,
        IonGrid,
        IonHeader,
        IonIcon,
        IonInput,
        IonLabel,
        IonMenuButton,
        IonPage,
        IonRow,
        IonSelect,
        IonSelectOption,
        IonTitle,
        IonToolbar,
        Dtable
    },
    data: function(){
      return{
        tasks: [],
        tasksHeading: [
          {
            text: 'Rif. Offerta',
            code: 'ref_off'
          },
          {
            text: 'Data Inizio',
            code: 'begindate'
          },
          {
            text: 'Data Fine',
            code: 'enddate'
          },
          {
            text: 'Cliente',
            code: 'account'
          },
          {
            text: 'Descrizione',
            code: 'description'
          },
          {
            text: '',
            code: 'btns'
          }
        ],
        tasksHeadingAdmin: [
          {
            text: 'Rif. Offerta',
            code: 'ref_off'
          },
          {
            text: 'Data Inizio',
            code: 'begindate'
          },
          {
            text: 'Data Fine',
            code: 'enddate'
          },
          {
            text: 'Risorsa',
            code: 'consultant'
          },
          {
            text: 'Cliente',
            code: 'account'
          },
          {
            text: 'Descrizione',
            code: 'description'
          },
          {
            text: '',
            code: 'btns'
          }
        ],
        table: {
          currentPage: 1,
          total: '',
          perPage: 0,
          orderBy: 'begindate',
          order: 'desc',
        },
        search:{
          begindate:'',
          enddate:'',
          ref_off:'',
          consultant:'',
          account:'',
          description:'',
          status:'1'
        },
        user:{
          id:0,
          name:'',
          role:0,
        },
        accounts:[],
        users:[],
        downloadOutline:downloadOutline,
      }
    },
  methods:{
    getTask(obj = false){
      if(obj) {
        this.table.currentPage = obj.page
        this.table.orderBy = obj.orderBy
        this.table.order = obj.order
      }
      this.axios.post(this.apiUrl + 'get-task', { page: this.table.currentPage, orderBy: this.table.orderBy, order: this.table.order, search:this.search }, this.headers)
      .then(async (response) => {
        this.tasks = response.data.tasks.data
        this.table.total = response.data.tasks.total
        this.table.perPage = response.data.tasks.per_page
      })
    },
    getAllTask(obj = false){
      if(obj) {
        this.table.currentPage = obj.page
        this.table.orderBy = obj.orderBy
        this.table.order = obj.order
      }
      this.axios.post(this.apiUrl + 'get-all-task', { page: this.table.currentPage, orderBy: this.table.orderBy, order: this.table.order, search:this.search }, this.headers)
      .then(async (response) => {
        this.tasks = response.data.tasks.data
        this.table.total = response.data.tasks.total
        this.table.perPage = response.data.tasks.per_page
      })
    },
    getConsultant(){
      this.axios.post(this.apiUrl + 'get-userInfo', {}, this.headers)
      .then(async (response) => {
        this.user=response.data.user;
      })
    },
    getAccountList(){
      this.axios.post(this.apiUrl + 'get-accounts-list', {}, this.headers)
      .then(async (response) => {
        this.accounts=response.data.accounts;
      })
    },
    getConsultantList(){
      this.axios.post(this.apiUrl + 'get-users-list', {}, this.headers)
      .then(async (response) => {
        this.users=response.data.users;
      })
    },
    svuotaFiltro(){
      this.search.ref_off='';
      this.search.begindate='',
      this.search.enddate='',
      this.search.consultant='',
      this.search.account='',
      this.search.description='';
      this.search.status='';
      if(this.user.role<5){
        this.getTask();
      }else{
        this.getAllTask();
      }
    },
    getUserInfo(){
      this.axios.post(this.apiUrl + 'get-userInfo', {}, this.headers)
      .then(async (response) => {
        this.user=response.data.user;
      })
    },
    esportaExcel(){
      if(this.user.role<=5){
        window.open('http://backend.hbj-group.com/download-excel-tasklogs?ref_off='+this.search.ref_off +'&begindate='+this.search.begindate +'&enddate='+this.search.enddate +'&consultant='+this.search.consultant +'&account='+this.search.account+'&description='+this.search.description+'&status='+this.search.status+'&utente='+this.user.id  +'&token='+this.$store.state.apiToken,'_blank')
      }else{
         window.open('http://backend.hbj-group.com/download-excel-tasklogs?ref_off='+this.search.ref_off +'&begindate='+this.search.begindate +'&enddate='+this.search.enddate +'&consultant='+this.search.consultant +'&account='+this.search.account+'&description='+this.search.description+'&status='+this.search.status +'&token='+this.$store.state.apiToken,'_blank')
      }
    },
  }, 
  ionViewWillEnter: function(){
    this.getUserInfo();
    this.getTask();
    this.getConsultant();
    this.getAccountList();
    this.getConsultantList();
  }
}
</script>

<style scoped>
  #container{
    width:90%;
    margin:5%; 
  }
  .headercol,.content, #visualizza{
      background-color:#9ea8cc48;
      min-width:40px;
      min-height:60px;
  }
  .contenuto{
    border-bottom-left-radius: 10px;
    padding: 30px
  }
  .header{
    background: darkblue;
    font-weight: bold;
    color:white;
    text-align:center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom:5px solid white;
  }
  .headercol{
    border-right:2px inset rgba(33, 209, 200, 0.479);
  }
  .content{
    border-right:2px inset rgba(33, 209, 200, 0.158);
    border-bottom:2px inset rgba(33, 209, 200, 0.158);
  }
  ion-button{
   width:100%;
  }
  .ricerca{
    margin:10px;
    border:1px solid grey;
    border-radius:5px;
    margin-bottom:5%;
  }
  #ricdatainizio,#ricdatafine,#ricconsulente,#riccliente,#ricdescrizione{
    border:1px solid rgb(184, 184, 184);
    border-radius:5px;
  }
  #ldatainizio{
    border:1px solid rgb(56,128,255);
    border-radius:5px;
    background-color:rgb(56,128,255);
    font-weight:bold;
    color:#fff;
  }
  #ldatafine{
    border:1px solid rgb(56,128,255);
    border-radius:5px;
    background-color:rgb(56,128,255);
    font-weight:bold;
    color:#fff;
  }
  #lconsulente{
    border:1px solid rgb(56,128,255);
    border-radius:5px;
    background-color:rgb(56,128,255);
    font-weight:bold;
    color:#fff;
  }
  #lcliente{
    border:1px solid rgb(56,128,255);
    border-radius:5px;
    background-color:rgb(56,128,255);
    font-weight:bold;
    color:#fff;
  }
  #ldescrizione{
    border:1px solid rgb(56,128,255);
    border-radius:5px;
    background-color:rgb(56,128,255);
    font-weight:bold;
    color:#fff;
  }
  .btncerca{
    width:92%;
  }
  .btnfiltro{
    width:5%;
  }
  #buttons{
    display:inline;
  }
  @media only screen and (max-width: 600px) {
    #datafine,#hdatafine,#hdescrizione,#descrizione{
      display:none;
    }
    .btncerca{
      width:80%;
    }
    .btnfiltro{
      width:5%;
    }
    #buttons{
      display:inline;
    }
  }
</style>